<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled label="Будинок" disabled v-model="building_name"
                          :class="building_name ? '' : 'req-star'"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-select :items="indicators_select" hide-details filled label="Вид показник" v-model="indicator_id"
                      :class="indicator_id ? '' : 'req-star'"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Значення" v-model.number="valueDec"
                          :class="valueDec ? '' : 'req-star'"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          color="grey"
                          v-decimal
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="dateStart" req label="Початок"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="dateEnd" label="Закінчення"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createBuildingIndicator" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {
  CREATE_BUILDING_INDICATOR,
  DELETE_BUILDING_INDICATOR,
  UPDATE_BUILDING_INDICATOR
} from "@/store/actions/building_indicators";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'building_indicator_modal_delete'

export default {
  name: "HWP_Modal_BuildingIdenticator.vue",
  mixins: [ModalComponentMixin],
  data() {
    return {
      building_name: this.item.building_name,
      building_id: this.item.building_id,
      indicator_id: this.item.indicator_id,
      valueDec: this.item.value_dec,
      dateStart: this.item.date_start,
      dateEnd: this.item.date_end,
    }
  },
  computed: {
    ...mapGetters({
      indicators_select: 'getBuildingIndicatorTypes',
    }),
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.building_name = this.item.building_name
      this.building_id = this.item.building_id
      this.indicator_id = this.item.indicator_id
      this.valueDec = this.item.value_dec
      this.dateStart = this.item.date_start
      this.dateEnd = this.item.date_end

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення якісного показника по будинку`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createBuildingIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Будинок, Вид показника, Значення та Дата початку - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        building_id: this.building_id,
        indicator_id: this.indicator_id,
        value_dec: this.valueDec,
        date_start: this.dateStart,
        date_end: this.dateEnd
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_BUILDING_INDICATOR, payload)
          .then(res => {
            if (res) {
              this.closeModal()
            }
          })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_BUILDING_INDICATOR, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.building_name = payload.building_name
              this.building_id = payload.building_id
              this.indicator_id = payload.indicator_id
              this.valueDec = payload.value_dec
              this.dateStart = payload.date_start
              this.dateEnd = payload.date_end
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_BUILDING_INDICATOR, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },

    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
